import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import cryptos from "../../Assets/Projects/cryptos.png";
import liqr from "../../Assets/Projects/liqr.png";
import topodata from "../../Assets/Projects/topodata.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Here are a few projects I've worked on:
        </h1>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cryptos}
              isBlog={false}
              title="Cryptos"
              description="Cryptos is an iOS application that gathers and displays data on the top cryptocurrencies in real-time.
              It uses our own backend API endpoints to render current and historical data. I built this with a team in which I principally
              served as the Front-End Engineer.

              Created with React-Native, MongoDB, Express, React Native, and NodeJS"
              ghLink="https://github.com/henrychen11/cryptos"
              demoLink="https://apps.apple.com/us/app/cryptosapp/id1328519917?ls=1"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={liqr}
              isBlog={false}
              title="liqr"
              description="liqr is a photo sharing application inspired by flicker. Users can upload images they want to share,
              view and comment on photos shared by other users, and organize pictures in dedicated albums.

              Created with Ruby on Rails and React/Redux."
              ghLink="https://github.com/henrychen11/liqr"
              demoLink="https://liqr.herokuapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={topodata}
              isBlog={false}
              title="Topodata"
              description="This project shows the average salary (wage) for all 50 states. Users has the ability to display historical data from 2011.
              Users will have the ability to filter by industry and also select the year. When users hover over each state, a tooltip will appear that
              shows additional details about that state.

              Created with D3 and vanilla JavaScript (ES6)."
              ghLink="https://github.com/henrychen11/topodata"
              demoLink="https://topodata.henryhchen.com/"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
