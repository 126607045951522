import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

function Timeline() {
    return (
        <VerticalTimeline>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.0)' }}
            >
                <h3 className="vertical-timeline-element-title">Engineering Manager</h3>
                <br/>
                <h4 className="vertical-timeline-element-subtitle">PayPal</h4>
                <p>Nov 2022 - Present</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.0)' }}
            >
                <h3 className="vertical-timeline-element-title">Engineering Manager</h3>
                <br/>
                <h4 className="vertical-timeline-element-subtitle">Lob</h4>
                <p>March 2021 - June 2022</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.0)' }}
            >
                <h3 className="vertical-timeline-element-title">Senior Software Engineer</h3>
                <br/>
                <h4 className="vertical-timeline-element-subtitle">Lob</h4>
                <p>Jan 2020 - March 2021</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.0)' }}
            >
                <h3 className="vertical-timeline-element-title">Software Engineer</h3>
                <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
                <p>Kraken</p>
                <p>March 2018 - Dec 2019</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.0)' }}
            >
                <h3 className="vertical-timeline-element-title">Financial Systems</h3>
                <br/>
                <h4 className="vertical-timeline-element-subtitle">Quantcast</h4>
                <p>Aug 2016 - Oct 2018</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.0)' }}
            >
                <h3 className="vertical-timeline-element-title">Technology Consultant</h3>
                <br/>
                <h4 className="vertical-timeline-element-subtitle">PricewaterhouseCoopers</h4>
                <p>Aug 2015 - Aug 2016</p>
            </VerticalTimelineElement>
        </VerticalTimeline>
    )
}

export default Timeline